<template>
  <v-dialog
      max-width="500px"
      v-model="dialogVisible"
      @click:outside="close"
      @keydown.esc="close"
  >
    <v-card max-width="500px">
      <v-card-title class="modal-title">
        <span>
          Отчет по движению товаров
        </span>

      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-5 w-100">
        <v-form class="fields"
                ref="form"
                v-model="valid">
          <v-text-field
            v-model="form.searchText"
            style="width: 100%"
            :rules="[v => !!v]"
            label="Штрихкод"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" text @click="close">Отменить</v-btn>
        <v-btn :loading="loading" @click="download" color="primary">Выгрузить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ExportForm',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        searchText: null,
      },
      valid: false,
      loading: false,
    };
  },

  methods: {
    download() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const query = Object.entries(this.form).map(([k, v]) => `${k}=${v}`).join('&');
        this.$reports
          .get(`/api/v1/clever-market-report/get-warehouse-operations-product-report-xls?${query}`)
          .then((res) => {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(res);
            a.download = 'Отчет по движению товара';
            a.click();
            this.loading = false;
            this.close();
          });
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.fields {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  justify-content: center;
  align-items: center;
}

.modal-title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}
</style>
