<template>
  <v-container fluid>
    <crm-table-pageable-new
      :headers="headers"
      :data="data"
      :config="config"
      :loaded-options="options"
      :total-elements="totalElements"
      searchPlaceholder="Поиск по товару"
      :total-pages="totalPages"
      :extraActions="extraActions"
      @extraAction="handleExtraAction"
      @onChangeData="onChangeData"
      @handleActions="handle"
    ></crm-table-pageable-new>
    <ExportForm
        v-if="dialogVisible"
        :dialog-visible="dialogVisible"
        @close="handleExtraActionClose"
    />
  </v-container>
</template>

<script>

import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import ExportForm from './ExportForm.vue';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    ExportForm,
  },

  data() {
    return {
      headers: [
        {
          value: 'id', sortable: true, text: 'ID', type: 'defaultItem', width: '30px',
        },
        {
          value: 'productName', sortable: false, text: 'Товар', type: 'defaultItem',
        },
        {
          value: 'supplier.id', sortable: false, text: 'ID поставщика', type: 'defaultItem',
        },
        {
          value: 'supplier.name', sortable: false, text: 'Поставщик', type: 'defaultItem',
        },
        {
          value: 'balance', sortable: false, text: 'Всего', type: 'defaultItem',
        },
        {
          value: 'minBalance', sortable: false, text: 'Мин. остаток', type: 'defaultItem',
        },
        {
          value: 'forSale', sortable: false, text: 'Доступен', type: 'defaultItem',
        },
        {
          value: 'reserved', sortable: false, text: 'Резерв', type: 'defaultItem',
        },
        {
          value: 'onDelivery', sortable: false, text: 'На доставке', type: 'defaultItem',
        },
        {
          value: 'sold', sortable: false, text: 'Продано', type: 'defaultItem',
        },
      ],
      data: [],
      createDialog: false,
      valid: false,
      item: {
        name: null,
      },
      config: {
        disableRowClick: true,
        add: false,
        search: true,
        pageable: true,
      },
      availableFilters: [],
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/wms/product/balance',
      options: {
        page: 1,
        size: 10,
        sort: 'id,desc',
      },
      extraActions: [
        {
          type: 'reports',
          action: 'export',
          title: 'Выгрузить отчет по движению товара',
        },
      ],
      dialogVisible: false,
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getBalanceList();
    },
    getBalanceList() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
          search: params.search,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = response.content;
      }).finally(() => {
        this.$loading(false);
      });
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          break;
        case 'check':
          break;
        case 'info':
          break;
        case 'delete':
          break;
        default:
          break;
      }
    },
    handleExtraAction(action) {
      if (action === 'export') {
        this.dialogVisible = true;
      }
    },
    handleExtraActionClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>

</style>
